::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #B5B5B5;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6A7A9B !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #6A7A9B !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #6A7A9B !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  border-radius: 0;
  font-family: inherit;
  font-family:"Roboto", sans-serif, Helvetica, Arial !important;
}
body{
  background: none;
  font-family:"Roboto", sans-serif, Helvetica, Arial !important;
}

a{
outline: none;
text-decoration: none;
}
a:focus{
outline: none!important;
}

html, body {
  font-family:"Roboto", sans-serif, Helvetica, Arial !important;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 760px) and (min-width: 320px)  {

body.doar-modal-open{
  overflow: hidden;
  height: 100vh;
}
}

.tnt.X{
  background:url('https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/xiconnew.png'), linear-gradient(90deg, #BE1C31 0%, #EC3F56 22.4%, #EC3F56 77.08%, #BE1C31 100%);
  /* background-size: 100% 100%; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  border: 0px;
  /* background-image: url('../public/icons/xo-logo-bg.png'); */
  

}

.signupbtn{
  font-family:"Roboto", sans-serif, Helvetica, Arial !important; font-weight: 600 !important; font-size: 0.9375rem !important; border-radius: 16px !important; padding: 7px 21px !important;
}
.loginbtn{
  font-family:"Roboto", sans-serif, Helvetica, Arial !important; font-weight: 600 !important; font-size: 0.9375rem !important; border-radius: 16px !important; padding: 7px 21px !important;
}

.termsandcondition p{ font-size: 1rem !important; font-weight: 400 !important; line-height: 1.25rem !important;  }

.termsandcondition h5{ font-size: 1.25rem !important; font-weight: 500 !important; line-height: 1.25rem !important;}

.submenuview ul a{ font-size: 14px !important; min-height: 40px !important; }

.tnt.O{
  background:url('https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/oiconnew.png'), linear-gradient(90deg, #031DF8 0%, #3046FF 22.4%, #3046FF 77.08%, #031DF8 100%);
  /* background-size: 100% 100%; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  border: 0px;
  /* background-image: url('../public/icons/xo-logo-bg.png'); */
}

.openclose button{border-radius: 8px;}

.amountbtn.Mui-selected{ border: 2px dotted rgba(243, 114, 44, 0.50) !important; background:rgba(243, 114, 44, 0.10)}

.gamex{
background:url('../public/icons/x-icon.png'), linear-gradient(90deg, #BE1C31 0%, #EC3F56 22.4%, #EC3F56 77.08%, #BE1C31 100%);
background-size: contain;
background-repeat: no-repeat;
background-position: center;
border-radius: 8px;


}
.gamex button{padding: 14px 10px;}
.gameo{
background:url('../public/icons/o-icon.png'), linear-gradient(90deg, #031DF8 0%, #3046FF 22.4%, #3046FF 77.08%, #031DF8 100%);
background-size: contain;
background-repeat: no-repeat;
background-position: center;

}
.gameo button{padding: 14px 10px;}

.gamebox{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
width: 100%;
}

.gamebox ul{
background: none;
padding: 0px;
margin: 0px;
}

.gamebox ul li{ padding: 0px;}

.gamebox ul li span{ font-size: 12px;}

.oddvalue{border-radius: 18px;
border: 1px solid #FFFFFFCB;
padding: 2px;
background-color: #00000021;
min-width: 5rem;
text-align: center;
color: #fff;}

.oddtext{color: #fff; margin-bottom: 15px;}

.oddvalue div  {border-radius: 16px;
  border: 1px solid #FFFFFF78;
  padding: 4px 16px;}

.gameopen{
min-height: 196px;
display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
border-radius: 7px;
border: 1px solid rgba(255, 255, 255, 0.24);
background: linear-gradient(90deg, rgba(0, 0, 0, 0.24) 0%, rgba(45, 45, 45, 0.24) 22.4%, rgba(47, 47, 47, 0.24) 77.08%, rgba(0, 0, 0, 0.24) 100%);
}

.paicingview{
padding-top: 8px;
padding-left: 8px;
/* padding-bottom: 8px; */
}

.pricingbtnview{ display: flex; align-items: center;  justify-content: center; }

.amountbtngroup{ width: 100%; padding-top: 8px; margin-left: 16px;}

.amountbtngroup button{ width: calc(100% / 4); }

.betamountenter{padding-left: 16px; width: calc(100% - 85px);
margin-right: 10px;}

/* .betamountenter .MuiInputBase-root{ border: 0px; border-radius: 0px; background: none;} */

.betamountenter input{
border-radius: 8px;
padding-left: 14px;
background-color: #40444569;
font-weight: 400;
font-size: 16px;
padding: 8.5px 14px;
border-radius: 8px;
}

/* .betamountenter .MuiOutlinedInput-notchedOutline{border: none;
background: none;} */

.gamegrideview{
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
border-radius: 8px;
box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12);
overflow: hidden;
z-index: 1;
max-width: 660px;
flex-shrink: 0;
width: 100%;
position: relative;
margin: 8px;
border: 1px solid #384766;
background: #121212A8;
-webkit-backdrop-filter: blur(2px);
backdrop-filter: blur(2px);
padding: 5px;
margin-top: 4px;
}

.divider{ width: 2px; height: 24px; margin: 0px auto; background: #FFFFFF29;}

.MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

/* .racecardmenu{
height: 300px;
} */

.racecardmenu li{
display: flex;
flex-direction: row;
align-items: flex-start;
position: relative;
border-bottom: 1px solid #FFFFFF69;
border-left: 3px solid transparent;
padding: 12px 16px;
margin-bottom: 4px;
margin-top: 4px;

}

.racenumber{
font-size: 14px !important;
font-weight: 600;
color: #fff;
}

.openforbet{ border-left: 3px solid rgb(84, 214, 44) !important;}

.closeforbet { border-left: 3px solid rgb(216, 8, 8) !important;}

.closeforbet p{ opacity: 0.4;}


.eventtime{
font-size: 14px !important;
line-height: 15px;
padding-bottom: 4px;
}

.eventname{
line-height: 13px;
padding-bottom: 6px;
}

.raceheaderbtn{
height: 28px;
border: 1px solid #FFFFFF69;
background: #121212A8;
border-radius: 8px;
padding: 0px 14px 0px 8px;
text-shadow: 1px 1px #00000069;
backdrop-filter: blur(2px);
width: auto;
min-width: 80px;
transition:none;
box-shadow:none;
font-size: 12px;
font-weight: 400;
display: flex;
}
.raceheaderbtn:hover{ background: none;}
.racecardmenu p{
font-size: 10px;
white-space: break-spaces;
word-break: break-word;

}

.racevenulist{
width: 140px;
display: flex;
flex-direction: column;
}

.opensub{

width: calc(100% - 140px);
display: flex;
flex-direction: column;
}

.openrace{
vertical-align: middle;
box-sizing: border-box;
background-color: transparent;
border: 1px solid rgba(84, 214, 44, 0.7);
color: #54D62C;
width: 100%;
padding: 2px;
margin-top: 4px;
border-radius: 16px;
height: 24px;
width: 80px;
font-size: 11px;
font-weight: 400;
display: flex;
align-items: center;
justify-content: center;
/* position: absolute; */
right: 20px;
top: 4px;
}

.closerace{
vertical-align: middle;
box-sizing: border-box;
background-color: transparent;
border: 1px solid rgb(216, 8, 8);
color: #d80808;
width: 100%;
padding: 2px;
margin-top: 4px;
border-radius: 16px;
height: 24px;
width: 80px;
font-size: 11px;
font-weight: 400;
display: flex;
align-items: center;
justify-content: center;
/* position: absolute; */
right: 10px;
top: 10px;
}

.betstatusopen svg{  color: rgb(82, 216, 67);}

.betstatusclose svg{  color: rgb(216, 8, 8);}

.racestatuscolor{ margin-right: 6px;}

.racestatuscolor svg{  color: rgb(82, 216, 67);}

.racemenulist{
width: 100%;
padding-left: 15px;
padding-top: 15px;
}

.confirmbetview .MuiPaper-elevation{
width: 320px;
background: #26334d;
border-radius: 30px;
padding: 20px;
}

.confirmbetview .MuiPaper-elevation h2{
padding-left: 0px; padding-right: 0px; text-align: center; padding-top: 0px;
}

.confirmbetview .MuiPaper-elevation .MuiDialogContent-root{
padding-left: 0px; padding-right: 0px;
}

.confirmbetview .MuiPaper-elevation .MuiDialogContent-root p{ color: #fff; font-size: 14px;}

.confirmcanclebtn{
background: none;
border: 1px solid #DD631A;
color: #dc6426;
display: flex;
align-items: center;
gap: 4px;
height: 42px;
font-size: 16px;
width: 100%;
text-align: center;
justify-content: center;
}

.confirmcanclebtn:hover{
background: none;
border: 1px solid #DD631A;
color: #dc6426;
display: flex;
align-items: center;
gap: 4px;
height: 42px;
font-size: 16px;
}

.confirmcanclebtn:active{
background: none;
border: 1px solid #DD631A !important;
color: #dc6426 !important;
display: flex;
align-items: center;
gap: 4px;
height: 42px;
font-size: 16px;
background: none !important;
}

.confirmbtn{
background: #DD631A;
display: flex;
align-items: center;
font-size: 16px;
border: 0px;
gap: 4px;
height: 42px;
color: #ffffff !important;
width: 100%;
text-align: center;
justify-content: center;
}

.confirmbtn:hover{
background: #DD631A;
display: flex;
align-items: center;
font-size: 16px;
border: 0px;
gap: 4px;
height: 42px;
color: #ffffff !important;
}

.confirmbtn:active{
background: #DD631A !important;
display: flex;
align-items: center;
font-size: 16px;
border: 0px;
gap: 4px;
height: 42px;
color: #ffffff !important;
}

.racecardvideo{
display: flex;
width: 100%;
justify-content: center;
}

.raceselectmenu .closed{
border:1px solid  rgb(216, 8, 8);
}

.raceselectmenu .closed .racestatuscolor svg{
color: rgb(216, 8, 8);
}

.raceselectmenu .open .racestatuscolor svg{
color: rgb(82, 216, 67);
}

.oddstext{
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.06);
  padding: 6px 8px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.60);
font-weight: 400;
text-align: center;
font-family: 'Roboto Condensed', sans-serif !important;
margin-top: 0px !important;
}

.eventlistview:hover{ background: none;}

/* @media (max-width: 580px) {
  .bannerimage{
    background-image: url("https://arionplay-media.s3.ap-southeast-1.amazonaws.com/backgrounds/tnt2mobile.jpg") !important ;
    height: 12rem !important;
    background-size: contain !important;
  }
} */

.racevideos{
z-index: 0;
position: relative;
overflow: hidden;
/* flex-grow: 1;
width: 100%;
height: 100%;
min-height: 72px; */
}

.homemainpage{
  padding-left: 24px;
  padding-right: 24px;
}

.accounttype{
  border-radius: 20px;
  border: 1px solid #616972;
  color: #B7B9BB;
  font-size: 12px;
  padding: 0px 14px;
  background:none;
  width: auto;
  height: 32px;
}

.accounttype::after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.username_text{
  font-family: "Roboto", sans-serif;}

.username_wallet{
    font-family: "Roboto", sans-serif;}

.gameoddevenview{
  display: flex;
  flex-direction:  row;
}
.closebetview{
  color: #FF6161;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  padding-left: 5px;
}

.eventsvgicon{
  display: flex;
  width: 20px;
  align-items: center;
}
.eventslist span{ font-size: 1rem; font-family: "Roboto", sans-serif, Helvetica, Arial; font-weight: 700; color: #e0e1e3; margin-left: 4px;}

.closebetview{
  font-size: 0.8rem !important; font-family: "Roboto", sans-serif, Helvetica, Arial; font-weight: 700; color: #e0e1e3; margin-left: 4px; line-height: 1.4;
  
}

.betcloseevent{
  background: rgba(0, 0, 0, 0.87);
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 9;
  
}

.betclosepopup{
  border-radius: 20px;
border: 1.5px solid rgba(97, 97, 97, 0.45);
background: linear-gradient(142deg, rgba(65, 69, 80, 0.80) 2.64%, rgba(32, 35, 43, 0.80) 96.27%);
backdrop-filter: blur(40px);
display: flex;
width: 300px;
padding: 20px 20px 20px 20px;
flex-direction: column;
align-items: center;
position: absolute;
top: 58%;
left: 50%;
transform: translate(-50%, -50%);
    
}

.betsucesspopupX{
  /* background: linear-gradient(223.53deg, #FF8292 3.65%, #FB5A6F 31.56%, #EA3E54 65.16%, #C32035 102.22%) !important; */
  background: linear-gradient(141.73deg, rgba(65, 69, 80, 0.8) 2.64%, rgba(32, 35, 43, 0.8) 96.27%);

}

.betsucesspopupO{
  background: linear-gradient(141.73deg, rgba(65, 69, 80, 0.8) 2.64%, rgba(32, 35, 43, 0.8) 96.27%);
}

.betsucesspopupC{
  background: linear-gradient(223.53deg, #C7C7C7 3.65%, #838383 102.22%) !important;
}

.betsucesspopupX .trendswineer{ background: #D3544E; display: flex; Padding:2px 6px 2px 6px; border-radius: 12px; margin-top: 10px;}

.betsucesspopupO .trendswineer{background: #565DF5; display: flex; Padding:2px 6px 2px 6px; border-radius: 12px; margin-top: 10px;}

.trendesview{ display: flex;     width: 100%;
  gap: 10px;}

.betsucesspopupO .trendso{    border: 2px solid rgb(86, 93, 245);
  height: 12px;
  width: 12px;
  border-radius: 100px;}

.betsucesspopupX .trendso{    border: 2px solid #D3544E;
  height: 12px;
  width: 12px;
  border-radius: 100px;}

.closeicon{
  border-radius: 16px;
border: 2px solid rgba(255, 97, 97, 0.30);
background: rgba(255, 255, 255, 0.06);
padding: 4px;
display: flex;
}

.closbtn{
  position: absolute;
  right: 14px;
  top: 20px;
  cursor: pointer;
}

.croenicons{
  border-radius: 16px;
border: 2px solid rgba(255, 255, 255, 0.30);
background: rgba(255, 255, 255, 0.06);
display: flex;
padding: 8px;
}

.trendsresultpopup{
  
background: linear-gradient(141.73deg, rgba(65, 69, 80, 0.8) 2.64%, rgba(32, 35, 43, 0.8) 96.27%);
background-color: #212B36;
border: 1.25px solid;
border-color: linear-gradient(153.49deg, rgba(97, 97, 97, 0.45) 4.12%, rgba(97, 97, 97, 0.35) 96.52%);
box-shadow: 0px 4px 4px 0px #00000040;
position: absolute;
/* transform: translate(-50%, -50%);
top: 50%;
left: 50%; */
z-index: 99999;
width: 94%;
-webkit-border-top-left-radius: 16px;
-webkit-border-top-right-radius: 16px;
-moz-border-radius-topleft: 16px;
-moz-border-radius-topright: 16px;
border-top-left-radius: 16px;
border-top-right-radius: 16px;
max-width: 600px;
bottom: 0px;



}

@media (max-width: 700px) {
  .trendsresultpopup{
    position: fixed !important;
  }

  .trandesresultbody{
    height: 480px !important;
  }

}

.trendsresulhead{ border-bottom: 1px solid #FFFFFF1A; padding: 10px 20px;
  min-height: 56px;}

.betsucesspopup{border-radius: 20px;
  background: linear-gradient(224deg, #FF8292 3.65%, #FB5A6F 31.56%, #EA3E54 65.16%, #C32035 102.22%);}

.starttime{ font-family: 'Pragati Narrow', sans-serif !important; font-weight: 400; font-size: 14px;}

/* .starttime span{ font-weight: 700;} */

.openclose button{
  padding: 1rem;
border: 1px solid rgba(255, 255, 255, 0.24);
background: linear-gradient(90deg, rgba(0, 0, 0, 0.24) 0%, rgba(45, 45, 45, 0.24) 22.4%, rgba(47, 47, 47, 0.24) 77.08%, rgba(0, 0, 0, 0.24) 100%);
}

/* .xotext{
  font-size: 28px !important;
} */

.depositbtntext{ background: #F3722C; color: #fff; border-radius: 6px; padding: 4px 10px;}

.depositbtntext svg path{ fill: #fff; }

.dropdown{ border-right: 1px solid #4c4c4c;
  padding-right: 4px;
  margin-right: 4px !important;}

  .starttime svg{ width: 13px;}

.starttime svg path{ stroke:#737575;}

.neweventslist{ 
  /* position: absolute; */
  top: 48px;
  width: 100%;
  padding:0px 16px;     background: #1b1f25;}

  .neweventslist ul{ display: flex; gap: 12px; }

  .neweventslist li{ width: 33%; padding: 0px 12px 0px 10px; border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.20); }

.racestatuschange{ height: 18px; width: 18px;}

.chiplabel span{ padding: 0px !important;}

.currentRace{ 
  border-radius: 8px;
border: 1px solid rgba(243, 114, 44, 0.40);
background: rgba(243, 114, 44, 0.10);
padding: 0px 12px 0px 8px;
}
.venunamerace{    
  /* border: 1px solid rgba(241, 114, 56, 0.5); */
    color: #fff !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    font-family: google_sansbold,Public Sans,sans-serif;
    font-style: normal !important;
    padding: 5px 15px;
    position: absolute;
    top: 0px;
    left: 395px;
    background: #000;}

.videovenunamerace{ color: #fff !important;
  font-weight: 900 !important;
  font-size: 16px !important;
  font-family: google_sansbold,Public Sans,sans-serif;
  font-style: normal !important;
  padding: 5px 15px;
  position: absolute;
  top: 5px;
  right: 415px;
  background: #000;}

.currentRace div{color: #F3722C; }

.currentRace .chiplabel span{ color: #F3722C !important;}

.chiplabel span{ padding: 0px !important;}

.gameoddeven{}

.myactivitytable .MuiPaper-elevation{ background:none !important}

.phoneotptext{  margin-bottom: 16px !important;}

.trandesresultbody{  height: 580px !important;}

.messageview{  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;}

.fontsize{font-size: 12px;}

    @media (max-width: 786px) {
      .racestatuschange{

        /* height: 18px;
        width: 23px; */
      }
      .fontsize{font-size: 10px; line-height: 14px;}

      .nowrap{ white-space: nowrap;}

      .racestatuschangesub{}

      .neweventslist{
        overflow: scroll;
      }

      .neweventslist ul{
        width: 110%;
      }

      .neweventslist li{
        padding: 0px 5px 0px 8px;
        width: 140px;
      }

      .chiplabel span{ padding: 0px !important;}

      .userracedetails{
        width: 100%;
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        flex-direction: column;
        gap: 0px !important;

      }

      .dotbar{ display: none !important;}
      .videovenunamerace{
        top: 10px;
        right: 5px;
      }
    }


@media (max-width: 580px) {

  /* .xotext{
    font-size: 22px !important;
  } */
  .gameoddevenview{
    display: flex;
    flex-direction: row;
  }
  .eventslist{
    display: flex;
  flex-direction: column;
  }
  .closebetview{
    color: #FF6161;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    position: relative;
    left: 0px;
    top: 0px;
    padding-left: 0px;
  }
  .popoverview{
    /* background: #212B36 !important; */
  }
 
  .gameoddeven{
    padding: 0px;
    background: #1b1f25;
    border-bottom: 1px solid #30363f;
    top: 0px;
    min-height: 54px;
    display: flex;
    align-items: center;
    width: 100%;
    position: inherit;
    padding: 8px 10px;
  }
  .gameoddeven button{
    width: 100%;
    border-radius: 0px;
  }
  .openclose button{ padding: 10px 4px 4px 4px;;}

  .racevs{ line-height: 16px; margin-top: 0px !important;}
}
@media (max-width: 370px) {
.starttime{
  font-size: 13px; white-space: nowrap; margin-top: 4px !important;
}
.oddstext{ padding: 5px 5px; }

.openclose button{ padding: 10px 4px 4px 4px;}

}

@keyframes firework {
  0% { transform: translate(var(--x), var(--initialY)); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
}

/* @keyframes fireworkPseudo {
  0% { transform: translate(-50%, -50%); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
}
 */
.firework,
.firework::before,
.firework::after
{
  --initialSize: 0.5vmin;
  --finalSize: 45vmin;
  --particleSize: 0.2vmin;
  --color1: yellow;
  --color2: khaki;
  --color3: white;
  --color4: lime;
  --color5: gold;
  --color6: mediumseagreen;
  --y: -30vmin;
  --x: -50%;
  --initialY: 60vmin;
  content: "";
  animation: firework 2s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, var(--y));
  width: var(--initialSize);
  aspect-ratio: 1;
  background: 
    /*
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 100%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 100%,
    */
    
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
    
    /* bottom right */
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
    
    /* bottom left */
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
    
    /* top left */
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
    
    /* top right */
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%
    ;
  background-size: var(--initialSize) var(--initialSize);
  background-repeat: no-repeat;
}

.firework::before {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
/*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
  transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
/*   animation: fireworkPseudo 2s infinite; */
}

.firework::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
/*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
  transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
/*   animation: fireworkPseudo 2s infinite; */
}

.firework:nth-child(2) {
  --x: 30vmin;
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after {
  --color1: pink;
  --color2: violet;
  --color3: fuchsia;
  --color4: orchid;
  --color5: plum;
  --color6: lavender;  
  --finalSize: 40vmin;
  left: 30%;
  top: 60%;
  animation-delay: -0.25s;
}

.firework:nth-child(3) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after {
  --color1: cyan;
  --color2: lightcyan;
  --color3: lightblue;
  --color4: PaleTurquoise;
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 35vmin;
  left: 70%;
  top: 60%;
  animation-delay: -0.4s;
}